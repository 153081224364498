import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "select", "input"]

  connect() {
    this.filterOnChange()
  }

  filterOnChange() {
    this.selectTargets.forEach(select => {
      select.addEventListener('change', () => this.submitForm())
    })
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }
}
