import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "autoSubmitInput", "textInput"]

  connect() {
    this.filterOnChange()
  }

  filterOnChange() {
    this.autoSubmitInputTargets.forEach(input => {
      input.addEventListener('change', () => this.submitForm())
    })
  }

  submitForm() {
    this.formTarget.requestSubmit()
  }
}
